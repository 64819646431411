import React, { useContext, useEffect } from "react";
import { SnackbarContext } from "../context/snackbar";
import { useHistory } from "react-router-dom";
import { GlobalLoaderContext } from "../context/global-loader";
import ApiUri from "../api/api-uri";
import API from "../api/api";
import { AuthContext } from "../context/auth";
import { HistoryOutlined } from "@mui/icons-material";

const AcceptInvitationLogged = () => {
  const history = useHistory();
  const { initSnackbarError, initSnackbarGeneric } =
    useContext(SnackbarContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);

  const { jwt } = useContext(AuthContext);

  const config = {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  };

  useEffect(() => {
    startGlobalLoader();
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    const formData = { token: token };

    API.post(
      ApiUri.INVITATION + ApiUri.ACCEPT,
      formData,
      jwt ? config : {}
    ).then(
      (response) => {
        localStorage.setItem("activationToken", "");
        stopGlobalLoader();
        if (response?.data?.data?.redirect_url) {
          window.location.replace(response?.data?.data?.redirect_url);
        } else {
          history.push("/");
        }
      },
      (error) => {
        if (
          error?.response?.data?.code === "login_required" ||
          error?.response?.data?.code === "signup_required"
        ) {
          localStorage.setItem("activationToken", token!);
          const app = error?.response?.data?.context?.application;
          const profile = error?.response?.data?.context?.profile;
          if (error?.response?.data?.code === "login_required") {
            initSnackbarGeneric(error?.response?.data?.message);
            initSnackbarError(error);
            stopGlobalLoader();
            history.push(`/${app}/${profile}`);
          } else if (error?.response?.data?.code === "signup_required") {
            initSnackbarGeneric(error?.response?.data?.message);
            stopGlobalLoader();
            history.push(`/${app}/signup/${profile}`);
          }
        } else if (
          error?.response?.data?.code === "accepted" ||
          error?.response?.data?.code === "invalid_invitation"
        ) {
          initSnackbarError(error?.response?.data?.message);
          stopGlobalLoader();
          history.push("/");
        } else {
          initSnackbarError(error?.response?.data?.message);
          stopGlobalLoader();
          history.push("/");
        }
      }
    );
  }, []);
  return <></>;
};

export default AcceptInvitationLogged;
